// Mixins
// Colours
$default: #000;
$background: #F8F9FC;
$black: #000;
$white: #FFF;
$red: #C01D1F;
$grey: #999;
$yellow: #FFF02F;
$link: $red;

// Secondary Colours
$ltgrey: #EEE;
$green: #007839;
$seagreen: #009EA5;
$pink: #E00085;

// Section Colours
$general: #007839;
$izindaba: #B31F24;
$imibono: #333;
$impilo-yabantu: #333;
$intandokazi: #ef4295;
$ezemidlalo: #4E8F13;
$ezokungcebeleka: #88171b;

// Social
$facebook: #3D5B9C;
$twitter: #000;
$instagram: #000;

// Fonts
$notoSerif: 'Noto Serif', 'Times New Roman', Arial, Helvetica, sans-serif;
$noto: 'Noto Sans', Arial, Helvetica, sans-serif;

@mixin font($font: $noto, $weight: 400, $style: normal) {
    font-family: $font;
    font-weight: $weight;
    font-style: $style;
}

//Rem Calc
@function rem-calc($font-size) {
	$rem-size: calc($font-size / 16);
	@return #{$rem-size}rem;
}

// Mixins
@mixin rem($property, $values) {
    $n: length($values);
    $i: 1;
    $browser-context: 16;
    $pxlist: ();
    $remlist: ();
    @while $i <=$n {
        $itemVal: (nth($values, $i));
        @if $itemVal != "auto" {
            $pxlist: append($pxlist, $itemVal + px);
            $remlist: append($remlist, calc($itemVal / $browser-context) + rem);
        }
        @else {
            $pxlist: append($pxlist, auto);
            $remlist: append($remlist, auto);
        }
        $i: $i + 1;
    }
    #{$property}: $pxlist;
    #{$property}: $remlist;
}

@mixin trans($type: all, $style: linear, $time: .3s) {
    -webkit-transition: $type $style $time;
       -moz-transition: $type $style $time;
    	-ms-transition: $type $style $time;
    	 -o-transition: $type $style $time;
        	transition: $type $style $time;
}

@mixin clearfix {
	&::after, &::before {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin center($xy:xy) {
     @if $xy==xy{
        left: 50%;
        top: 50%;
        bottom: auto;
        right: auto;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    @else if $xy==x {
        left: 50%;
        right: auto;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    @else if $xy==y {
        top: 50%;
        bottom: auto;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

// Aspect Ration
@mixin aspect-ratio($width, $height) {
    position: relative;
    overflow: hidden;
    display: block;
    padding-top: calc($height / $width) * 100%;
    img,
    video {
        position: absolute;
        @include center(xy);
    }
}

// Placeholder
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #F1F1F1;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #F1F1F1;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #F1F1F1;
}
:-moz-placeholder { /* Firefox 18- */
    color: #F1F1F1;
}
