// Article List
@media screen and (min-width: 769px) {
    .article-listing, .article-page {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: calc(100% - 320px) 300px;
    }
    .sidebar {
        grid-column: 2 / span 1;
    }
    .article-list {
        grid-column: 1 / span 1;
        // padding: 0 20px 0 0;
    }
}

.article-listing, .article-page {
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .sidebar {
        margin-top: 20px;
    }
}

// Breaking News
.breaking-news {
    span {
        display: inline-block;
        color: $red;
        background: lighten($red, 45%);
        padding: 8px 12px;
        margin-right: 10px;
        @media screen and (max-width: 767px) {
            display: block;
            text-align: center;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
    a:hover {
        color: $red;
    }
}

// Featured Articles
.featured-articles {
    margin-top: 20px;
    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        @include clearfix;
        padding-right: 310px;
    }
    .content {
        h3 {
            border-bottom: 0 none;
            padding: 0;
            margin: 0;
            text-transform: none;
        }
        small {
            @include rem('font-size', 12);
            line-height: 1;
        }
    }
    article, > div {
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 767px) {
            min-height: 295px;
            margin-bottom: 10px;
        }
        a {
            display: block;
            height: 100%;
            &:hover {
                color: $green;
            }
        }
    }
    article {
        @media screen and (min-width: 768px) {
            float: left;
            width: calc((100% - 40px) / 3);
            margin-right: 20px;
        }
        margin-bottom: 10px;
    }
    > div {
        margin: 0;
        width: 300px;
        height: 600px;
        position: absolute;
        top: 0;
        right: 0;
        @media screen and (max-width: 767px) {
            position: relative;
            min-height: 250px;
        }
    }
    article {
        img {
            width: 100%;
            height: auto;
            margin: auto;
            margin-bottom: 10px;
        }

    }
    @media screen and (min-width: 768px) {
        article:nth-of-type(3n) {
            margin-right: 0;
        }
        article:nth-of-type(3n+1) {
            clear: left;
        }
    }
    article {
        @media screen and (max-width: 767px) {
            border-bottom: 1px solid $ltgrey;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        h3 {
            @include rem('font-size', 20);
        }
        p {
            margin-bottom: 0;
        }
    }
    > div {
        img {
            margin: auto;
        }
    }
    @media screen and (max-width: 767px) {
        .ad-mpudynamic-topright {
            height: auto;
            margin: auto;
            margin-bottom: 20px;
        }
    }
    .highlights-image {
        position: relative;
        small {
            position: absolute;
            bottom: 0;
            padding: 8px 10px;
            display: inline-block;
            background: #007839;
            color: #FFF;
            line-height: 1;
        }
    }
}

// News Highlights
.highlights {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-areas: "a a"
                             "b c"
                             "b d"
                             "b e"
                             "b f";
        grid-gap: 20px;
        grid-template-columns: repeat(2, calc((100% - 20px) / 2));
    }
    &:first-of-type {
        .section-head {
            margin-top: 0;
        }
    }
    h1, h2 {
        @include rem('font-size', 20);
        position: relative;
        @media screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
    article {
        @media screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
        &:first-of-type {
            grid-area: b;
            @media screen and (max-width: 767px) {
                margin-top: 40px;
            }
            h3 {
                @include rem('font-size', 24);
                margin-bottom: 10px;
            }
            small {
                margin: 5px 0;
                display: block;
            }
        }
        &:nth-of-type(2) {
            grid-area: c;
        }
        &:nth-of-type(3) {
            grid-area: d;
        }
        &:nth-of-type(4) {
            grid-area: e;
        }
        &:nth-of-type(5) {
            grid-area: f;
        }
        &:not(:first-of-type) {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: 100px auto;
            .content {
                align-self: start;
            }
        }
        p {
            margin: 0;
        }
        h3 {
            border-bottom: 0 none;
            padding-bottom: 0;
            margin-bottom: 0;
            text-transform: none;
        }
        a:hover {
            color: $green;
        }
        &:not(:first-of-type) {
            small {
                @include rem('font-size', 12);
                margin-top: 0;
                margin-bottom: 8px;
                line-height: 1.1;
                display: block;
            }
        }
    }
    &.multimedia {
        background: #37474F;
        margin: 20px 0 0;
        padding: 20px;
        color: $white;
        @media screen and (min-width: 768px) {
            display: grid;
            grid-template-areas: "a a"
                                "b c"
                                "b d"
                                "b e"
                                "b f";
            grid-gap: 20px;
            grid-template-columns: repeat(2, calc((100% - 20px) / 2));
        }
        .section-head {
            margin-top: 0;
            h2 {
                margin-top: 0;
            }
            a {
                svg {
                    path {
                        fill: $white;
                    }
                }
                &:hover {
                    svg path {
                        fill: darken($white, 25%);
                    }
                }
            }
        }
        a {
            color: darken($white, 10%);
                &:hover {
                    color: darken($white, 25%);
                }
        }
        article {
            display: block;
            &:not(:first-of-type) {
                display: grid;
                grid-gap: 10px;
                grid-template-columns: 100px auto;
                .content {
                    align-self: start;
                }
            }
            > a:first-of-type {
                position: relative;
                display: block;
                .play-btn {
                    position: absolute;
                    line-height: 1;
                    @include center(xy);
                    svg path {
                        fill: $red;
                        @include trans();
                    }
                }
            }
        }
    }
}

// Section Page
.related-sections {
    @media screen and (min-width: 768px){
        display: grid;
        grid-template-areas: "a b";
        grid-template-columns: 130px auto;
    }
    margin: 20px 0;
    text-transform: uppercase;
    @include rem('font-size', 12);
    nav {
        grid-area: b;
    }
    ul, li, p {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    a {
        display: block;
        padding: 0 5px 0 0;
        &::after {
            content: ', ';
        }
        &:hover {
            color: $red;
        }
    }
    li {
        display: inline-block;
        &:last-of-type {
            a {
                &::after {
                    content: '';
                }
            }
        }
    }
    p {
        grid-area: a;
        color: $grey;
    }
}
.section-page {
    h1 {
        @include rem('font-size', 24);
    }
    .sections {
        @media screen and (min-width: 768px){
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(2, calc((100% - 20px) / 2));
        }
        article {
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 20px;
            align-content: start;
            border-bottom: 1px solid #EEE;
            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
            > a {
                @include aspect-ratio(16, 9);
                img {
                    @include center(x);
                    top: 0;
                }
            }
            h3 {
                border-bottom: 0 none;
                text-transform: none;
            }
            a:hover {
                color: $green;
            }
        }
        .advert-leaderboard {
            @media screen and (max-width: 767px) {
                border-bottom: 1px solid #EEE;
            }
            grid-column: 1 / span 2;
            grid-gap: 20px;
            div {
                padding-top: 0;
            }
        }
    }
}

// More From
.more-from-btn {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        margin: auto;
        border: 1px solid $grey;
        padding: 10px 20px;
        background: $background;
        cursor: pointer;
        color: $grey;
        @include trans();
        &:hover, &:focus {
            border-color: $green;
            color: $green;
        }
    }
}

// No Search Results
.no-results {
    h1 {
        @include rem('font-size', 24);
    }
    img {
        margin: 20px auto 40px;
    }
    .no-results-search {
        text-align: center;
    }
}

// Search Results
.search-page {
    margin-top: 20px;
    h1 {
        @include rem('font-size', 20);
        margin-bottom: 20px;
    }
}
.search-results {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(4, calc((100% - 60px) / 4));
        grid-gap: 20px;
    }
    h3 {
        border-bottom: 0 none;
        text-transform: none;
    }
}

// Section Head
.section-head {
    grid-area: a;
    position: relative;
    margin-top: 5px;
    .related-sections {
        margin-bottom: 0;
    }
    h1, h2 {
        line-height: 34px;
        @include rem('font-size', 20);
    }
    h2 {
        margin-top: 30px;
    }
    > a {
        @include rem('font-size', 14);
        position: absolute;
        right: 0;
        top: 5px;
        svg {
            padding-top: 2px;
        }
        @media screen and (max-width: 767px) {
            top: 100%;
            padding: 10px 0;
        }
    }
    a:hover {
        color: $green;
        svg path {
            fill: $green;
        }
    }
    .subsection-buttons {
        position: absolute;
        top: 0;
        right: 0;
        a {
            display: inline-block;
            padding: 3px 8px;
            border: 1px solid $default;
            margin-right: 5px;
            @include rem('font-size', 14);
            &:last-of-type {
                margin-right: 0;
            }
            &:hover {
                border-color: $green;
            }
        }
    }
}

#buttonContainer {
    > div {
        > div {
            background-color: $green;
        }
    }
}